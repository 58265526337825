.gallery-list {
  text-align: left;
}
.gallery-item-delete {
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
  color: red;
  padding: 2px;
  border-radius: 50%;
}
.gallery-item-delete:hover {
  color: white;
  background-color: red;
}
.gallery-item-box {
  position: relative;
}
.gallery-item-eye {
  position: absolute;
  right: 10px;
  bottom: 0px;
  cursor: pointer;
  border-radius: 50%;
}
.gallery-nm-item,
.gallery-sm-item {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.gallery-nm-item a,
.gallery-sm-item a {
  display: flex;
  margin: 10px;
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  text-align: center;
  vertical-align: center;
  justify-content: center;
  align-items: center;
}
.gallery-nm-item {
  width: 120px;
}
.gallery-nm-item a {
  width: 100px;
  height: 100px;
}
.gallery-nm-item a img {
  max-width: 90px;
  height: 90px;
}
.gallery-sm-item {
  width: 70px;
}
.gallery-sm-item a {
  width: 50px;
  height: 60px;
}
.gallery-sm-item a img {
  max-width: 40px;
  height: 40px;
}
.gallery-text {
  padding: 0 10px;
  font-size: 12px;
  word-break: break-all;
  text-align: center;
  line-height: 1;
  color: #999;
}
